<template>
  <svg
    :width="props.width"
    :height="props.height"
    viewBox="0 0 48 48"
    role="presentation"
  >
    <g :fill="props.iconColor" :opacity="props.iconOpacity">
      <path
        d="M21.05 28.55 16.15 23.65Q15.7 23.2 15.05 23.2Q14.4 23.2 13.95 23.65Q13.45 24.15 13.45 24.825Q13.45 25.5 13.9 25.95L20 32.05Q20.4 32.45 21.05 32.45Q21.7 32.45 22.1 32.05L34.1 20.05Q34.55 19.6 34.55 18.95Q34.55 18.3 34.05 17.8Q33.6 17.35 32.925 17.35Q32.25 17.35 31.75 17.85ZM24 44Q19.75 44 16.1 42.475Q12.45 40.95 9.75 38.25Q7.05 35.55 5.525 31.9Q4 28.25 4 24Q4 19.8 5.525 16.15Q7.05 12.5 9.75 9.8Q12.45 7.1 16.1 5.55Q19.75 4 24 4Q28.2 4 31.85 5.55Q35.5 7.1 38.2 9.8Q40.9 12.5 42.45 16.15Q44 19.8 44 24Q44 28.25 42.45 31.9Q40.9 35.55 38.2 38.25Q35.5 40.95 31.85 42.475Q28.2 44 24 44Z"
      />
    </g>
  </svg>
</template>

<script setup>
const props = defineProps({
  width: {
    type: [Number, String],
    default: 38,
  },
  height: {
    type: [Number, String],
    default: 38,
  },
  iconColor: {
    type: String,
    default: "#000000",
  },
  iconOpacity: {
    type: [Number, String],
    default: 1,
  },
});
</script>
